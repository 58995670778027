import React from "react";
import Layout from "../../components/layout/Layout";
import ResponsiveContainer from "../../components/ui/ResponsiveContainer";

import * as styles from "./styles.module.scss";

const PrivacyPolicy = () => {
  return (
    <Layout title='Datenschutzerklärung'>
      <ResponsiveContainer>
        <div className={styles.privacypolicy}>
          <h1 data-fontsize='32' data-lineheight='38.4px'>
            Datenschutzerklärung
          </h1>
          <h2 id='verarbeitung-daten' data-fontsize='24' data-lineheight='36px'>
            Verarbeitung persönlicher Daten
          </h2>
          Jeder Teilnehmer erklärt sich damit einverstanden, dass seine Daten (Vorname, Nachname und
          Email Adresse) gespeichert werden. Diese Informationen dienen ausschließlich zur Erfassung
          und als Nachweis der Unterstützer*innen der Initiative dubistmehrwert. Diese
          Einverständniserklärung kann per E-Mail: info@dubistmehrwert.at jederzeit widerrufen
          werden.
          <h2 id='cookie-deklaration' data-fontsize='24' data-lineheight='36px'>
            Cookies
          </h2>
          <script
            id='CookieDeclaration'
            src='https://consent.cookiebot.com/89a343f7-86e1-4e1a-b4a0-8ac4065bafe8/cd.js'
            type='text/javascript'
            async></script>
          <h2 id='einleitung-ueberblick' data-fontsize='24' data-lineheight='36px'>
            Einleitung und Überblick
          </h2>
          <p>
            Wir haben diese Datenschutzerklärung (Fassung 11.09.2022-112119510) verfasst, um Ihnen
            gemäß der Vorgaben der{" "}
            <a
              href='https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112119510#d1e2269-1-1'
              target='_blank'
              rel='noopener'>
              Datenschutz-Grundverordnung (EU) 2016/679
            </a>{" "}
            und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz
            Daten) wir als Verantwortliche – und die von uns beauftragten Auftragsverarbeiter (z. B.
            Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
            Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.
            <br />
            <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie
            verarbeiten.
          </p>
          <p>
            Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische
            Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so
            einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich
            ist, werden technische <strong>Begriffe leserfreundlich erklärt</strong>, Links zu
            weiterführenden Informationen geboten und <strong>Grafiken</strong> zum Einsatz
            gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen
            unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine
            entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man
            möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im
            Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die
            folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder
            andere Information dabei, die Sie noch nicht kannten.
            <br />
            Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im
            Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und
            sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie
            selbstverständlich auch im Impressum.
          </p>
          <h2 id='anwendungsbereich' data-fontsize='24' data-lineheight='36px'>
            Anwendungsbereich
          </h2>
          <p>
            Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten
            personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte
            Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir
            Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und
            postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt
            dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei
            es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:
          </p>
          <ul>
            <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
            <li>Social Media Auftritte und E-Mail-Kommunikation</li>
            <li>mobile Apps für Smartphones und andere Geräte</li>
          </ul>
          <p>
            <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen
            personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet
            werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten,
            werden wir Sie gegebenenfalls gesondert informieren.
          </p>
          <h2 id='rechtsgrundlagen' data-fontsize='24' data-lineheight='36px'>
            Rechtsgrundlagen
          </h2>
          <p>
            In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den
            rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der
            Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.
            <br />
            Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES
            EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese
            Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem
            Zugang zum EU-Recht, unter{" "}
            <a href='https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679'>
              https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679
            </a>{" "}
            nachlesen.
          </p>
          <p>
            Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:
          </p>
          <ol>
            <li>
              <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre
              Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel
              wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
            </li>
            <li>
              <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder
              vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten.
              Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab
              personenbezogene Informationen.
            </li>
            <li>
              <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir
              einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel
              sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese
              enthalten in der Regel personenbezogene Daten.
            </li>
            <li>
              <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle
              berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die
              Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten
              verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu
              können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
            </li>
          </ol>
          <p>
            Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und
            Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns
            in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte,
            wird diese an der entsprechenden Stelle ausgewiesen.
          </p>
          <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
          <ul>
            <li>
              In <strong>Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher
              Personen bei der Verarbeitung personenbezogener Daten (
              <strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>.
            </li>
            <li>
              In <strong>Deutschland</strong> gilt das <strong>Bundesdatenschutzgesetz</strong>,
              kurz<strong> BDSG</strong>.
            </li>
          </ul>
          <p>
            Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir
            Sie in den folgenden Abschnitten darüber.
          </p>
          <h2 id='speicherdauer' data-fontsize='24' data-lineheight='36px'>
            Speicherdauer
          </h2>
          <p>
            Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung
            unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles
            Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der
            Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir
            gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches
            Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.
          </p>
          <p>
            Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur
            Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine
            Pflicht zur Speicherung besteht, gelöscht.
          </p>
          <p>
            Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter
            unten, sofern wir weitere Informationen dazu haben.
          </p>
          <h2 id='rechte-dsgvo' data-fontsize='24' data-lineheight='36px'>
            Rechte laut Datenschutz-Grundverordnung
          </h2>
          <p>
            Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen
            zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:
          </p>
          <ul>
            <li>
              Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen
              verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu
              erhalten und die folgenden Informationen zu erfahren:
              <ul>
                <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
                <li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                <li>
                  wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie
                  die Sicherheit garantiert werden kann;
                </li>
                <li>wie lange die Daten gespeichert werden;</li>
                <li>
                  das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der
                  Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
                </li>
                <li>
                  dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen
                  Behörden finden Sie weiter unten);
                </li>
                <li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                <li>
                  ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um
                  zu einem persönlichen Profil von Ihnen zu gelangen.
                </li>
              </ul>
            </li>
            <li>
              Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet,
              dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
            </li>
            <li>
              Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“),
              was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.
            </li>
            <li>
              Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was
              bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.
            </li>
            <li>
              Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass
              wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.
            </li>
            <li>
              Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine
              Änderung der Verarbeitung mit sich bringt.
              <ul>
                <li>
                  Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches
                  Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f
                  (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch
                  einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch
                  rechtlich nachkommen können.
                </li>
                <li>
                  Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen
                  diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht
                  mehr für Direktmarketing verwenden.
                </li>
                <li>
                  Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen
                  diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht
                  mehr für Profiling verwenden.
                </li>
              </ul>
            </li>
            <li>
              Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich
              auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden
              Entscheidung unterworfen zu werden.
            </li>
            <li>
              Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich
              jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die
              Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.
            </li>
          </ul>
          <p>
            <strong>Kurz gesagt:</strong> Sie haben Rechte – zögern Sie nicht, die oben gelistete
            verantwortliche Stelle bei uns zu kontaktieren!
          </p>
          <p>
            Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt
            oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind,
            können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die
            Datenschutzbehörde, deren Website Sie unter{" "}
            <a href='https://www.dsb.gv.at/?tid=112119510' target='_blank' rel='noopener'>
              https://www.dsb.gv.at/
            </a>{" "}
            finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für
            nähere Informationen können Sie sich an die{" "}
            <a
              href='https://www.bfdi.bund.de/DE/Home/home_node.html'
              target='_blank'
              rel='noopener'>
              Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)
            </a>{" "}
            wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:
          </p>
          <h2 id='oesterreich-datenschutzbehoerde' data-fontsize='24' data-lineheight='36px'>
            Österreich Datenschutzbehörde
          </h2>
          <p>
            <strong>Leiterin: </strong>Mag. Dr. Andrea Jelinek
            <strong>
              <br />
              Adresse:{" "}
            </strong>
            Barichgasse 40-42, 1030 Wien
            <strong>
              <br />
              Telefonnr.:{" "}
            </strong>
            +43 1 52&nbsp;152-0
            <strong>
              <br />
              E-Mail-Adresse:{" "}
            </strong>
            <a href='mailto:dsb@dsb.gv.at' target='_blank' rel='noopener'>
              dsb@dsb.gv.at
            </a>
            <strong>
              <br />
              Website:{" "}
            </strong>
            <a href='https://www.dsb.gv.at/' target='_blank' rel='noopener'>
              https://www.dsb.gv.at/
            </a>
          </p>
          <h2 id='datenuebertragung-drittlaender' data-fontsize='24' data-lineheight='36px'>
            Datenübertragung in Drittländer
          </h2>
          <p>
            Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU (Drittländer),
            wenn Sie dieser Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder
            vertraglich notwendig und in jedem Fall nur soweit dies generell erlaubt ist. Ihre
            Zustimmung ist in den meisten Fällen der wichtigste Grund, dass wir Daten in
            Drittländern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in
            Drittländern wie den USA, wo viele Softwarehersteller Dienstleistungen anbieten und Ihre
            Serverstandorte haben, kann bedeuten, dass personenbezogene Daten auf unerwartete Weise
            verarbeitet und gespeichert werden.
          </p>
          <p>
            Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen Gerichtshofs
            derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die
            Datenverarbeitung durch US-Dienste (wie beispielsweise Google Analytics) kann dazu
            führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden.
            Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne
            Daten nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten aus anderen
            Diensten desselben Anbieters, sofern Sie ein entsprechendes Nutzerkonto haben, verknüpft
            werden. Nach Möglichkeit versuchen wir Serverstandorte innerhalb der EU zu nutzen,
            sofern das angeboten wird.
          </p>
          <p>
            Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung genauer über
            Datenübertragung in Drittländer, sofern diese zutrifft.
          </p>
          <h2 id='sicherheit-datenverarbeitung' data-fontsize='24' data-lineheight='36px'>
            Sicherheit der Datenverarbeitung
          </h2>
          <p>
            Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch
            organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder
            pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer
            Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche
            Informationen schließen können.
          </p>
          <p>
            Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen” und meint damit, dass man sowohl bei Software
            (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit
            denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich,
            noch auf konkrete Maßnahmen ein.
          </p>
          <h2 id='tls-verschluesselung-https' data-fontsize='24' data-lineheight='36px'>
            TLS-Verschlüsselung mit https
          </h2>
          <p>
            TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden
            HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres
            Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.
            <br />
            Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem
            Webserver abgesichert ist – niemand kann “mithören”.
          </p>
          <p>
            Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den
            Datenschutz durch Technikgestaltung (
            <a
              href='https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112119510'
              target='_blank'
              rel='noopener'>
              Artikel 25 Absatz 1 DSGVO
            </a>
            ). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll
            zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten
            sicherstellen.
            <br />
            Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen
            Schlosssymbol{" "}
            <img
              role='img'
              src='https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg'
              width='17'
              height='18'
            />{" "}
            links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der
            Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
            <br />
            Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche
            nach “Hypertext Transfer Protocol Secure wiki” um gute Links zu weiterführenden
            Informationen zu erhalten.
          </p>
          <h2 id='kommunikation' data-fontsize='24' data-lineheight='36px'>
            Kommunikation
          </h2>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Kommunikation Zusammenfassung</strong>
                  <br />
                  👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular
                  kommunizieren
                  <br />
                  📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene
                  Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten
                  Kontaktart
                  <br />
                  🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.
                  <br />
                  📅 Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften
                  <br />
                  ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                  b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular
            kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.
          </p>
          <p>
            Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit
            zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange
            gespeichert bzw. solange es das Gesetz vorschreibt.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Betroffene Personen
          </h3>
          <p>
            Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten
            Kommunikationswege den Kontakt zu uns suchen.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Telefon
          </h3>
          <p>
            Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim
            eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können
            Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur
            Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der
            Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            E-Mail
          </h3>
          <p>
            Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem
            jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt zur
            Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der
            Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Online Formulare
          </h3>
          <p>
            Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem
            Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet.
            Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche
            Vorgaben erlauben.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Rechtsgrundlagen
          </h3>
          <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
          <ul>
            <li>
              Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten
              zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
            </li>
            <li>
              Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung
              eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter
              oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung
              eines Angebots, verarbeiten;
            </li>
            <li>
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und
              geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind
              gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
              Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.
            </li>
          </ul>
          <h2 id='cookies' data-fontsize='24' data-lineheight='36px'>
            Cookies
          </h2>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Cookies Zusammenfassung</strong>
                  <br />
                  👥 Betroffene: Besucher der Website
                  <br />
                  🤝 Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter
                  unten bzw. beim Hersteller der Software, der das Cookie setzt.
                  <br />
                  📓 Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu
                  finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.
                  <br />
                  📅 Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu
                  Jahren variieren
                  <br />
                  ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                  lit.f DSGVO (Berechtigte Interessen)
                </td>
              </tr>
            </tbody>
          </table>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Was sind Cookies?
          </h3>
          <p>
            Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.
            <br />
            Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die
            folgende Datenschutzerklärung besser verstehen.
          </p>
          <p>
            Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser
            sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die
            meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man
            Cookies.
          </p>
          <p>
            Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast
            alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch
            noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine
            Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
            Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers,
            untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition
            eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
          </p>
          <p>
            Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder
            persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr
            Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß
            unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In
            einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise
            Firefox sind alle Cookies in einer einzigen Datei gespeichert.
          </p>
          <p>
            Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B.
            Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom
            Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite
            angefordert wird.
          </p>
          <p>
            <img
              role='img'
              src='https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg'
              alt='HTTP Cookie Interaktion zwischen Browser und Webserver'
              width='100%'
            />
          </p>
          <p>
            Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies
            werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von
            Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu
            bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
            variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine
            Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies
            können auch nicht auf Informationen Ihres PCs zugreifen.
          </p>
          <p>So können zum Beispiel Cookie-Daten aussehen:</p>
          <p>
            <strong>Name:</strong> _ga
            <br />
            <strong>Wert:</strong>&nbsp;GA1.2.1326744211.152112119510-9
            <br />
            <strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher
            <br />
            <strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren
          </p>
          <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
          <ul>
            <li>Mindestens 4096 Bytes pro Cookie</li>
            <li>Mindestens 50 Cookies pro Domain</li>
            <li>Mindestens 3000 Cookies insgesamt</li>
          </ul>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Welche Arten von Cookies gibt es?
          </h3>
          <p>
            Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten
            ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser
            Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
          </p>
          <p>Man kann 4 Arten von Cookies unterscheiden:</p>
          <p>
            <strong>
              Unerlässliche Cookies
              <br />
            </strong>
            Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum
            Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann
            auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird
            der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
          </p>
          <p>
            <strong>
              Zweckmäßige Cookies
              <br />
            </strong>
            Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige
            Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das
            Verhalten der Website bei verschiedenen Browsern gemessen.
          </p>
          <p>
            <strong>
              Zielorientierte Cookies
              <br />
            </strong>
            Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden
            eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
          </p>
          <p>
            <strong>
              Werbe-Cookies
              <br />
            </strong>
            Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User
            individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr
            nervig sein.
          </p>
          <p>
            Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser
            Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem
            Cookie gespeichert.
          </p>
          <p>
            Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen,
            empfehlen wir{" "}
            <a href='https://datatracker.ietf.org/doc/html/rfc6265'>
              https://datatracker.ietf.org/doc/html/rfc6265
            </a>
            ,&nbsp;dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP
            State Management Mechanism”.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Zweck der Verarbeitung über Cookies
          </h3>
          <p>
            Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie
            weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Welche Daten werden verarbeitet?
          </h3>
          <p>
            Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in
            Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie
            im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten
            Daten informieren.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Speicherdauer von Cookies
          </h3>
          <p>
            Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert.
            Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere
            Jahre auf einem Computer gespeichert bleiben.
          </p>
          <p>
            Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser
            sämtliche Cookies jederzeit manuell löschen (siehe auch unten “Widerspruchsrecht”).
            Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf
            Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin
            unberührt bleibt.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Widerspruchsrecht – wie kann ich Cookies löschen?
          </h3>
          <p>
            Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem
            Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit
            Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können
            Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.
          </p>
          <p>
            Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn
            Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren
            Browser-Einstellungen finden:
          </p>
          <p>
            <a
              href='https://support.google.com/chrome/answer/95647?tid=112119510'
              target='_blank'
              rel='noopener noreferrer'>
              Chrome: Cookies in Chrome löschen, aktivieren und verwalten
            </a>
          </p>
          <p>
            <a
              href='https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=112119510'
              target='_blank'
              rel='noopener noreferrer'>
              Safari: Verwalten von Cookies und Websitedaten mit Safari
            </a>
          </p>
          <p>
            <a
              href='https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=112119510'
              target='_blank'
              rel='noopener noreferrer'>
              Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer
              abgelegt haben
            </a>
          </p>
          <p>
            <a href='https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=112119510'>
              Internet Explorer: Löschen und Verwalten von Cookies
            </a>
          </p>
          <p>
            <a href='https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=112119510'>
              Microsoft Edge: Löschen und Verwalten von Cookies
            </a>
          </p>
          <p>
            Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so
            einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können
            Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die
            Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in
            Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome”
            im Falle eines Chrome Browsers.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Rechtsgrundlage
          </h3>
          <p>
            Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das
            Speichern von Cookies eine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO)
            von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr
            unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die
            Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In
            Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt.
            Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
            Telemediengesetzes (TMG).
          </p>
          <p>
            Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen{" "}
            <strong>berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den
            meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine
            angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt
            notwendig.
          </p>
          <p>
            Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im
            Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.
          </p>
          <p>
            In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert,
            sofern eingesetzte Software Cookies verwendet.
          </p>
          <h2 id='web-analytics-einleitung' data-fontsize='24' data-lineheight='36px'>
            Web Analytics Einleitung
          </h2>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Web Analytics Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  👥 Betroffene: Besucher der Website
                  <br />
                  🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.
                  <br />
                  📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                  Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und
                  IP-Adressen enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Web
                  Analytics Tool.
                  <br />
                  📅 Speicherdauer: abhängig vom eingesetzten Web-Analytics-Tool
                  <br />
                  ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                  f DSGVO (Berechtigte Interessen)
                </td>
              </tr>
            </tbody>
          </table>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Was ist Web Analytics?
          </h3>
          <p>
            Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der
            Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei werden Daten
            gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch Trackingtool genannt)
            speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über das
            Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verfügung
            gestellt. Zusätzlich bieten die meisten Tools verschiedene Testmöglichkeiten an. So
            können wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten
            ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei verschiedene
            Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher
            Inhalt unsere Websitebesucher interessanter finden. Für solche Testverfahren, wie auch
            für andere Analytics-Verfahren, können auch Userprofile erstellt werden und die Daten in
            Cookies gespeichert werden.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Warum betreiben wir Web Analytics?
          </h3>
          <p>
            Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für unsere Branche
            das beste Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir
            einerseits das beste und interessanteste Angebot bieten und andererseits darauf achten,
            dass Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von Webanalyse-Tools
            können wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann
            entsprechend unser Webangebot für Sie und uns verbessern. So können wir beispielsweise
            erkennen wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere
            Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt
            sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an
            Ihre Bedürfnisse, Interessen und Wünsche anzupassen.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Welche Daten werden verarbeitet?
          </h3>
          <p>
            Welche Daten genau gespeichert werden, hängt natürlich von den verwendeten Analyse-Tools
            ab. Doch in der Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer
            Website ansehen, auf welche Buttons oder Links Sie klicken, wann Sie eine Seite
            aufrufen, welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.)
            Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit
            einverstanden waren, dass auch Standortdaten erhoben werden dürfen, können auch diese
            durch den Webanalyse-Tool-Anbieter verarbeitet werden.
          </p>
          <p>
            Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung
            (DSGVO) sind IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der
            Regel pseudonymisiert (also in unkenntlicher und gekürzter Form) gespeichert. Für den
            Zweck der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich keine
            direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse
            gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert
            gespeichert. So können Sie als Person nicht identifiziert werden.
          </p>
          <p>
            Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als
            Beispiel für client-basiertes Webtracking mit Java-Script-Code.
          </p>
          <p>
            <img
              role='img'
              src='https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg'
              alt='Schematischer Datenfluss bei Google Analytics'
              width='100%'
            />
          </p>
          <p>
            Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom Anbieter ab. Manche
            Cookies speichern Daten nur für ein paar Minuten bzw. bis Sie die Website wieder
            verlassen, andere Cookies können Daten über mehrere Jahre speichern.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            <span
              data-sheets-value='{"1":2,"2":"Wo und wie lange werden Daten gespeichert?"}'
              data-sheets-userformat='{"2":769,"3":{"1":0},"11":4,"12":0}'>
              Dauer der Datenverarbeitung
            </span>
          </h3>
          <p>
            Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir
            weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so
            lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt
            notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich
            vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Widerspruchsrecht
          </h3>
          <p>
            Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung
            von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser
            Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Beispiel können Sie auch
            die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies
            verwalten, deaktivieren oder löschen.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Rechtsgrundlage
          </h3>
          <p>
            Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem
            Cookie Popup eingeholt haben. Diese Einwilligung stellt laut
            <strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die
            Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics
            Tools vorkommen kann, dar.
          </p>
          <p>
            Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran,
            das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und
            wirtschaftlich zu verbessern. Mit Hilfe von Web-Analytics erkennen wir Fehler der
            Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die
            Rechtsgrundlage dafür ist{" "}
            <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die
            Tools gleichwohl nur ein, soweit sie eine Einwilligung erteilt haben.
          </p>
          <p>
            Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das
            Lesen unserer allgemeinen Datenschutzerklärung zu Cookies. Um zu erfahren, welche Daten
            von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die
            Datenschutzerklärungen der jeweiligen Tools durchlesen.
          </p>
          <p>
            Informationen zu speziellen Web-Analytics-Tools, erhalten Sie – sofern vorhanden – in
            den folgenden Abschnitten.
          </p>
          <h2 id='google-analytics-datenschutzerklaerung' data-fontsize='24' data-lineheight='36px'>
            Google Analytics Datenschutzerklärung
          </h2>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Google Analytics Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  👥 Betroffene: Besucher der Website
                  <br />
                  🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.
                  <br />
                  📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                  Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und
                  IP-Adressen enthalten. Mehr Details dazu finden Sie weiter unten in dieser
                  Datenschutzerklärung.
                  <br />
                  📅 Speicherdauer: abhängig von den verwendeten Properties
                  <br />
                  ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                  f DSGVO (Berechtigte Interessen)
                </td>
              </tr>
            </tbody>
          </table>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Was ist Google Analytics?
          </h3>
          <p>
            Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des
            amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen
            Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle
            Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf
            unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in
            einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die
            wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an
            Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und
            informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das
            verhindern können.
          </p>
          <p>
            Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website
            dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer
            Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene
            Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website
            verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort
            gespeichert.
          </p>
          <p>
            Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei
            kann es sich unter anderem um folgende Berichte handeln:
          </p>
          <ul>
            <li>
              Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und
              wissen genauer, wer sich für unser Service interessiert.
            </li>
            <li>
              Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter
              analysieren und verbessern.
            </li>
            <li>
              Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber,
              wie wir mehr Menschen für unser Service begeistern können.
            </li>
            <li>
              Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir
              können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links
              Sie anklicken.
            </li>
            <li>
              Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer
              Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von
              einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe
              dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen
              ankommen. So wollen wir unsere Conversionrate steigern.
            </li>
            <li>
              Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website
              passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.
            </li>
          </ul>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Warum verwenden wir Google Analytics auf unserer Website?
          </h3>
          <p>
            Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service
            bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu
            erreichen.
          </p>
          <p>
            Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und
            Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie
            von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns
            die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
            unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die
            Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und
            kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und
            Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Welche Daten werden von Google Analytics gespeichert?
          </h3>
          <p>
            Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID,
            die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen
            User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“
            User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So
            ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.
          </p>
          <p>
            Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in
            den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property
            gespeichert. Für jede neu angelegte Property ist die Google Analytics 4-Property
            standardmäßig. Alternativ kann man aber auch noch die Universal Analytics Property
            erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.
          </p>
          <p>
            Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf
            unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf
            unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein
            Google-Konto) nützen, können über Google Analytics generierte Daten mit
            Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter,
            außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es
            gesetzlich erforderlich ist.
          </p>
          <p>Folgende Cookies werden von Google Analytics verwendet:</p>
          <p>
            <strong>Name:</strong> _ga
            <br />
            <strong>Wert: </strong>2.1326744211.152112119510-5
            <br />
            <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga,
            um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der
            Webseitenbesucher.
            <br />
            <strong>Ablaufdatum:</strong> nach 2 Jahren
          </p>
          <p>
            <strong>Name:</strong> _gid
            <br />
            <strong>Wert: </strong>2.1687193234.152112119510-1
            <br />
            <strong>Verwendungszweck:</strong>&nbsp;Das Cookie dient auch zur Unterscheidung der
            Webseitenbesucher
            <br />
            <strong>Ablaufdatum:</strong>&nbsp;nach 24 Stunden
          </p>
          <p>
            <strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;
            <br />
            <strong>Wert:</strong> 1<br />
            <strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn
            Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie
            den Namen _dc_gtm_ &lt;property-id&gt;.
            <br />
            <strong>Ablaufdatum: </strong>nach 1 Minute
          </p>
          <p>
            <strong>Name:</strong> AMP_TOKEN
            <br />
            <strong>Wert:</strong> keine Angaben
            <br />
            <strong>Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom
            AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine
            Abmeldung, eine Anfrage oder einen Fehler hin.
            <br />
            <strong>Ablaufdatum:</strong>&nbsp;nach 30 Sekunden bis zu einem Jahr
          </p>
          <p>
            <strong>Name:</strong> __utma
            <br />
            <strong>Wert: </strong>1564498958.1564498958.1564498958.1
            <br />
            <strong>Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der
            Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn
            Informationen an Google Analytics gesendet werden.
            <br />
            <strong>Ablaufdatum:</strong> nach 2 Jahren
          </p>
          <p>
            <strong>Name:</strong> __utmt
            <br />
            <strong>Wert:</strong> 1<br />
            <strong>Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt;
            zum Drosseln der Anforderungsrate verwendet.
            <br />
            <strong>Ablaufdatum:</strong>&nbsp;nach 10 Minuten
          </p>
          <p>
            <strong>Name:</strong> __utmb
            <br />
            <strong>Wert: </strong>3.10.1564498958
            <br />
            <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu
            bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google
            Analytics gesendet werden.
            <br />
            <strong>Ablaufdatum:</strong>&nbsp;nach 30 Minuten
          </p>
          <p>
            <strong>Name:</strong> __utmc
            <br />
            <strong>Wert:</strong> 167421564
            <br />
            <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für
            wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und
            wird nur solange gespeichert, bis Sie den Browser wieder schließen.
            <br />
            <strong>Ablaufdatum:</strong> Nach Schließung des Browsers
          </p>
          <p>
            <strong>Name:</strong> __utmz
            <br />
            <strong>Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/
            <br />
            <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des
            Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie
            speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw.
            eine Werbeschaltung gewesen sein.
            <br />
            <strong>Ablaufdatum:</strong> nach 6 Monaten
          </p>
          <p>
            <strong>Name:</strong> __utmv
            <br />
            <strong>Wert:</strong> keine Angabe
            <br />
            <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte
            Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google
            Analytics gesendet werden.
            <br />
            <strong>Ablaufdatum:</strong> nach 2 Jahren
          </p>
          <p>
            <strong>Anmerkung:</strong>&nbsp;Diese Aufzählung kann keinen Anspruch auf
            Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.
          </p>
          <p>
            Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google
            Analytics erhoben werden:
          </p>
          <p>
            <strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man
            genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf
            unserer Seite „unterwegs“ sind.
          </p>
          <p>
            <strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie
            auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv
            waren, endet die Sitzung automatisch.
          </p>
          <p>
            <strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn
            Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.
          </p>
          <p>
            <strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw.
            eine Bestellung machen, erhebt Google Analytics diese Daten.
          </p>
          <p>
            <strong>IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt,
            damit keine eindeutige Zuordnung möglich ist.
          </p>
          <p>
            <strong>Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer Standort
            bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.
          </p>
          <p>
            <strong>Technische Informationen:</strong> Zu den technischen Informationen zählen unter
            anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.
          </p>
          <p>
            <strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert
            natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen
            sind.
          </p>
          <p>
            Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z. B.
            wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social
            Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen
            Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der
            Datenspeicherung durch Google Analytics.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Wie lange und wo werden die Daten gespeichert?
          </h3>
          <p>
            Google hat ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in
            Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert.
            Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden:{" "}
            <a
              href='https://www.google.com/about/datacenters/locations/?hl=de'
              target='_blank'
              rel='follow noopener'>
              https://www.google.com/about/datacenters/locations/?hl=de
            </a>
          </p>
          <p>
            Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den
            Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt
            sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre
            Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen
            Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.
          </p>
          <p>
            Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der
            Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer
            Userdaten auf 14 Monate fix eingestellt. Für andere sogenannte Ereignisdaten haben wir
            die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.
          </p>
          <p>
            Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer
            Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten
            gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten
            selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:
          </p>
          <ul>
            <li>Löschung nach 14 Monaten</li>
            <li>Löschung nach 26 Monaten</li>
            <li>Löschung nach 38 Monaten</li>
            <li>Löschung nach 50 Monaten</li>
            <li>Keine automatische Löschung</li>
          </ul>
          <p>
            Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie
            innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem
            Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt, wenn Sie unsere Website
            innerhalb des festgelegten Zeitraums wieder besuchen.
          </p>
          <p>
            Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht.
            Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und
            Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse
            basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
            Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren
            Einheit.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
          </h3>
          <p>
            Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre
            Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des
            Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js,
            dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on
            können Sie unter{" "}
            <a
              href='https://tools.google.com/dlpage/gaoptout?hl=de'
              target='_blank'
              rel='noopener noreferrer'>
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>{" "}
            runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die
            Datenerhebung durch Google Analytics deaktiviert wird.
          </p>
          <p>
            Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten wollen, finden Sie
            unter dem Abschnitt „Cookies“ die entsprechenden Links zu den jeweiligen Anleitungen der
            bekanntesten Browser.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Rechtsgrundlage
          </h3>
          <p>
            Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem
            Cookie Popup eingeholt haben. Diese Einwilligung stellt laut
            <strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die
            Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics
            Tools vorkommen kann, dar.
          </p>
          <p>
            Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran,
            dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und
            wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der
            Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die
            Rechtsgrundlage dafür ist{" "}
            <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google
            Analytics gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.
          </p>
          <p>
            Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass
            nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für
            den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die
            Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.
          </p>
          <p>
            Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb
            der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA)
            oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln
            (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses
            – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen
            sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards
            entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und
            dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der
            Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten,
            selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese
            Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den
            Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier:{" "}
            <a
              href='https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de'
              target='_blank'
              rel='noopener'>
              https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
            </a>
          </p>
          <p>
            Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche
            auf die Standardvertragsklauseln verweisen, finden Sie unter{" "}
            <a
              href='https://business.safety.google/intl/de/adsprocessorterms/'
              target='_blank'
              rel='follow noopener'>
              https://business.safety.google/intl/de/adsprocessorterms/
            </a>
            .
          </p>
          <p>
            Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die
            Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den
            Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links:{" "}
            <a href='https://marketingplatform.google.com/about/analytics/terms/de/'>
              https://marketingplatform.google.com/about/analytics/terms/de/
            </a>{" "}
            und{" "}
            <a
              href='https://support.google.com/analytics/answer/6004245?hl=de'
              target='_blank'
              rel='noopener noreferrer'>
              https://support.google.com/analytics/answer/6004245?hl=de
            </a>
            .
          </p>
          <h2 id='e-mail-marketing-einleitung' data-fontsize='24' data-lineheight='36px'>
            E-Mail-Marketing Einleitung
          </h2>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>E-Mail-Marketing Zusammenfassung</strong>
                  <br />
                  👥 Betroffene: Newsletter-Abonnenten
                  <br />
                  🤝 Zweck: Direktwerbung per E-Mail, Benachrichtigung über systemrelevante
                  Ereignisse
                  <br />
                  📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens
                  die E-Mail-Adresse. Mehr Details dazu finden Sie beim jeweils eingesetzten
                  E-Mail-Marketing-Tool.
                  <br />
                  📅 Speicherdauer: Dauer des Bestehens des Abonnements
                  <br />
                  ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                  f DSGVO (berechtigte Interessen)
                </td>
              </tr>
            </tbody>
          </table>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Was ist E-Mail-Marketing?
          </h3>
          <p>
            Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die Möglichkeit des
            E-Mail-Marketings. Dabei werden, sofern Sie dem Empfang unserer E-Mails bzw. Newsletter
            zugestimmt haben, auch Daten von Ihnen verarbeitet und gespeichert. E-Mail-Marketing ist
            ein Teilbereich des Online-Marketings. Dabei werden Neuigkeiten oder allgemeine
            Informationen über ein Unternehmen, Produkte oder Dienstleistungen per E-Mail an eine
            bestimmte Gruppe an Menschen, die sich dafür interessieren, gesendet.
          </p>
          <p>
            Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen wollen, müssen
            Sie sich im Normalfall einfach nur mit Ihrer E-Mail-Adresse anmelden. Dafür füllen Sie
            ein Online-Formular aus und senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa
            um Ihre Anrede und Ihren Namen bitten, damit wir Sie auch persönlich anschreiben können.
          </p>
          <p>
            Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe des sogenannten
            „Double-Opt-In-Verfahrens“. Nachdem Sie sich für unseren Newsletter auf unserer Website
            angemeldet haben, bekommen Sie eine E-Mail, über die Sie die Newsletter-Anmeldung
            bestätigen. So wird sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich
            niemand mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns
            verwendetes Benachrichtigungs-Tool protokolliert jede einzelne Anmeldung. Dies ist
            nötig, damit wir den rechtlich korrekten Anmeldevorgang auch nachweisen können. Dabei
            wird in der Regel der Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebestätigung und
            Ihre IP-Adresse gespeichert. Zusätzlich wird auch protokolliert, wenn Sie Änderungen
            Ihrer gespeicherten Daten vornehmen.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Warum nutzen wir E-Mail-Marketing?
          </h3>
          <p>
            Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets die wichtigsten
            Neuigkeiten über unser Unternehmen präsentieren. Dafür nutzen wir unter anderem
            E-Mail-Marketing – oft auch nur “Newsletter” bezeichnet – als wesentlichen Bestandteil
            unseres Online-Marketings. Sofern Sie sich damit einverstanden erklären oder es
            gesetzlich erlaubt ist, schicken wir Ihnen Newsletter, System-E-Mails oder andere
            Benachrichtigungen per E-Mail. Wenn wir im folgenden Text den Begriff „Newsletter“
            verwenden, meinen wir damit hauptsächlich regelmäßig versandte E-Mails. Natürlich wollen
            wir Sie mit unseren Newsletter in keiner Weise belästigen. Darum sind wir wirklich stets
            bemüht, nur relevante und interessante Inhalte zu bieten. So erfahren Sie etwa mehr über
            unser Unternehmen, unsere Leistungen oder Produkte. Da wir unsere Angebote auch immer
            verbessern, erfahren Sie über unseren Newsletter auch immer, wenn es Neuigkeiten gibt
            oder wir gerade spezielle, lukrative Aktionen anbieten. Sofern wir einen Dienstleister,
            der ein professionelles Versand-Tool anbietet, für unser E-Mail-Marketing beauftragen,
            machen wir das, um Ihnen schnelle und sichere Newsletter bieten zu können. Zweck unseres
            E-Mail-Marketings ist grundsätzlich, Sie über neue Angebote zu informieren und auch
            unseren unternehmerischen Zielen näher zu kommen.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Welche Daten werden verarbeitet?
          </h3>
          <p>
            Wenn Sie über unsere Website Abonnent unseres Newsletters werden, bestätigen Sie per
            E-Mail die Mitgliedschaft in einer E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse
            können auch Ihre Anrede, Ihr Name, Ihre Adresse und Ihre Telefonnummer gespeichert
            werden. Allerdings nur, wenn Sie dieser Datenspeicherungen zustimmen. Die als solche
            markierten Daten sind notwendig, damit Sie an dem angebotenen Dienst teilnehmen können.
            Die Angabe ist freiwillig, die Nichtangabe führt jedoch dazu, dass Sie den Dienst nicht
            nutzen können. Zusätzlich können etwa auch Informationen zu Ihrem Gerät oder zu Ihren
            bevorzugten Inhalten auf unserer Website gespeichert werden. Mehr zur Speicherung von
            Daten, wenn Sie eine Website besuchen, finden Sie im Abschnitt “Automatische
            Datenspeicherung”. Ihre Einwilligungserklärung zeichnen wir auf, damit wir stets
            nachweisen können, dass dieser unseren Gesetzen entspricht.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Dauer der Datenverarbeitung
          </h3>
          <p>
            Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler austragen, dürfen
            wir Ihre Adresse bis zu drei Jahren auf Grundlage unserer berechtigten Interessen
            speichern, damit wir Ihre damalige Einwilligung noch nachweisen können. Verarbeiten
            dürfen wir diese Daten nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.
          </p>
          <p>
            Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur Newsletter-Anmeldung
            gegeben haben, können Sie jederzeit einen individuellen Löschantrag stellen.
            Widersprechen Sie der Einwilligung dauerhaft, behalten wir uns das Recht vor, Ihre
            E-Mail-Adresse in einer Sperrliste zu speichern. Solange Sie freiwillig unseren
            Newsletter abonniert haben, solange behalten wir selbstverständlich auch Ihre
            E-Mail-Adresse.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Widerspruchsrecht
          </h3>
          <p>
            Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu kündigen. Dafür müssen
            Sie lediglich Ihre Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im
            Normalfall nur wenige Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt
            am Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu kündigen. Wenn der Link
            im Newsletter wirklich nicht zu finden ist, kontaktieren Sie uns bitte per Mail und wir
            werden Ihr Newsletter-Abo unverzüglich kündigen.
          </p>
          <h3 data-fontsize='21' data-lineheight='31.5px'>
            Rechtsgrundlage
          </h3>
          <p>
            Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer{" "}
            <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO). Das heißt, wir dürfen
            Ihnen nur dann einen Newsletter schicken, wenn Sie sich zuvor aktiv dafür angemeldet
            haben. Gegebenenfalls können wir Ihnen auch Werbenachrichten auf Grundlage des § 7 Abs.
            3 UWG senden, sofern Sie unser Kunde geworden sind und der Verwendung ihrer
            E-Mailadresse für Direktwerbung nicht widersprochen haben.
          </p>
          <p>
            Informationen zu speziellen E-Mail-Marketing Diensten und wie diese personenbezogene
            Daten verarbeiten, erfahren Sie – sofern vorhanden – in den folgenden Abschnitten.
          </p>
          <h2 id='erklaerung-verwendeter-begriffe' data-fontsize='24' data-lineheight='36px'>
            Erklärung verwendeter Begriffe
          </h2>
          <p>
            Wir sind stets bemüht unsere Datenschutzerklärung so klar und verständlich wie möglich
            zu verfassen. Besonders bei technischen und rechtlichen Themen ist das allerdings nicht
            immer ganz einfach. Es macht oft Sinn juristische Begriffe (wie z. B. personenbezogene
            Daten) oder bestimmte technische Ausdrücke (wie z. B. Cookies, IP-Adresse) zu verwenden.
            Wir möchte diese aber nicht ohne Erklärung verwenden. Nachfolgend finden Sie nun eine
            alphabetische Liste von wichtigen verwendeten Begriffen, auf die wir in der bisherigen
            Datenschutzerklärung vielleicht noch nicht ausreichend eingegangen sind. Falls diese
            Begriffe der DSGVO entnommen wurden und es sich um Begriffsbestimmungen handelt, werden
            wir hier auch die DSGVO-Texte anführen und gegebenenfalls noch eigene Erläuterungen
            hinzufügen.
          </p>
          <h2 id='einwilligung' data-fontsize='24' data-lineheight='36px'>
            Einwilligung
          </h2>
          <p>
            <strong>Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
          </p>
          <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
          <blockquote>
            <p>
              <em>
                <strong>„Einwilligung“</strong> der betroffenen Person jede freiwillig für den
                bestimmten Fall, in informierter Weise und unmissverständlich abgegebene
                Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen
                bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie
                mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist;
              </em>
            </p>
          </blockquote>
          <p>
            <strong>Erläuterung: </strong>In der Regel erfolgt bei Websites eine solche Einwilligung
            über ein Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine
            Website besuchen, werden Sie meist über einen Banner gefragt, ob Sie der
            Datenverarbeitung zustimmen bzw. einwilligen. Meist können Sie auch individuelle
            Einstellungen treffen und so selbst entscheiden, welche Datenverarbeitung Sie erlauben
            und welche nicht. Wenn Sie nicht einwilligen, dürfen auch keine personenbezogene Daten
            von Ihnen verarbeitet werden. Grundsätzlich kann eine Einwilligung natürlich auch
            schriftlich, also nicht über ein Tool, erfolgen.
          </p>
          <h2 id='personenbezogene-daten' data-fontsize='24' data-lineheight='36px'>
            Personenbezogene Daten
          </h2>
          <p>
            <strong>Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
          </p>
          <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
          <blockquote>
            <p>
              <strong>
                <em>„personenbezogene Daten“</em>
              </strong>
              <em>
                {" "}
                alle Informationen, die sich auf eine identifizierte oder identifizierbare
                natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar
                wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere
                mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
                Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen
                Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
                wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person
                sind, identifiziert werden kann;
              </em>
            </p>
          </blockquote>
          <p>
            <strong>Erläuterung:</strong> Personenbezogene Daten sind also all jene Daten, die Sie
            als Person identifizieren können. Das sind in der Regel Daten wie etwa:
          </p>
          <ul>
            <li>Name</li>
            <li>Adresse</li>
            <li>E-Mail-Adresse</li>
            <li>Post-Anschrift</li>
            <li>Telefonnummer</li>
            <li>Geburtsdatum</li>
            <li>
              Kennnummern wie Sozialversicherungsnummer, Steueridentifikationsnummer,
              Personalausweisnummer oder Matrikelnummer
            </li>
            <li>Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.</li>
          </ul>
          <p>
            Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre{" "}
            <strong>IP-Adresse zu den personenbezogenen Daten</strong>. IT-Experten können anhand
            Ihrer IP-Adresse zumindest den ungefähren Standort Ihres Geräts und in weiterer Folge
            Sie als Anschlussinhabers feststellen. Daher benötigt auch das Speichern einer
            IP-Adresse eine Rechtsgrundlage im Sinne der DSGVO. Es gibt auch noch sogenannte{" "}
            <strong>„besondere Kategorien“</strong> der personenbezogenen Daten, die auch besonders
            schützenswert sind. Dazu zählen:
          </p>
          <ul>
            <li>rassische und ethnische Herkunft</li>
            <li>politische Meinungen</li>
            <li>religiöse bzw. weltanschauliche Überzeugungen</li>
            <li>die Gewerkschaftszugehörigkeit</li>
            <li>
              genetische Daten wie beispielsweise Daten, die aus Blut- oder Speichelproben entnommen
              werden
            </li>
            <li>
              biometrische Daten (das sind Informationen zu psychischen, körperlichen oder
              verhaltenstypischen Merkmalen, die eine Person identifizieren können).
              <br />
              Gesundheitsdaten
            </li>
            <li>Daten zur sexuellen Orientierung oder zum Sexualleben</li>
          </ul>
          <h2 id='profiling' data-fontsize='24' data-lineheight='36px'>
            Profiling
          </h2>
          <p>
            <strong>Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
          </p>
          <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
          <blockquote>
            <p>
              <em>
                <strong>„Profiling“</strong> jede Art der automatisierten Verarbeitung
                personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten
                verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
                Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung,
                wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen,
                Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
                Person zu analysieren oder vorherzusagen;
              </em>
            </p>
          </blockquote>
          <p>
            <strong>Erläuterung:</strong> Beim Profiling werden verschiedene Informationen über eine
            Person zusammengetragen, um daraus mehr über diese Person zu erfahren. Im Webbereich
            wird Profiling häufig für Werbezwecke oder auch für Bonitätsprüfungen angewandt. Web-
            bzw. Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten und Ihre
            Interessen auf einer Website. Daraus ergibt sich ein spezielles Userprofil, mit dessen
            Hilfe Werbung gezielt an eine Zielgruppe ausgespielt werden kann.
          </p>
          <p>&nbsp;</p>
          <p>Alle Texte sind urheberrechtlich geschützt.</p>
          <p>
            Quelle: Erstellt mit dem{" "}
            <a
              href='https://www.adsimple.at/datenschutz-generator/'
              title='Datenschutz Generator von AdSimple für Österreich'>
              Datenschutz Generator
            </a>{" "}
            von AdSimple
          </p>
          <br />
          <strong>Newsletter Datenschutzerklärung: Sendinblue</strong>
          <a href='https://de.sendinblue.com/legal/privacypolicy/'>
            Sendinblue Datenschutzerklärung
          </a>
        </div>
      </ResponsiveContainer>
    </Layout>
  );
};

export default PrivacyPolicy;
